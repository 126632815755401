import React from "react";
import Cart from "./Cart";
import { compose } from "redux";
import { connect } from "react-redux";
import { getProductsInfo, initProducts } from "../../../redux/reducers/shop-reducer";
import breakpoints from "../../../assets/theme/base/breakpoints";
import MobileCart from "./MobileCart";

class CartContainer extends React.Component {

  state = {
    mobileView: false,
  };

  displayMobile() {
    if (window.innerWidth < breakpoints.values.lg) {
      this.setState({ mobileView: true });
    } else {
      this.setState({ mobileView: false });
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.displayMobile.bind(this));
    this.displayMobile();

    if (!this.props.isProductsInit) {
      this.props.initProducts();
    }
    this.props.getProductsInfo();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.displayMobile.bind(this));
  }

  render() {
    if (this.state.mobileView) {
      return <MobileCart productsInfo={this.props.productsInfo} productsCart={this.props.productsCart} />
    }

    return <Cart productsInfo={this.props.productsInfo} productsCart={this.props.productsCart} />;
  }
}

const mapStateToProps = (state) => ({
  isProductsInit: state.shop.isProductsInit,
  productsInfo: state.shop.productsInfo,
  productsCart: state.shop.productsCart,
});

export default compose(
  connect(mapStateToProps, { getProductsInfo, initProducts }),
)(CartContainer);