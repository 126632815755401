import React from "react";
import Grid from "@mui/material/Grid";
import VacancyItem from "./VacancyItem";
import Typography from "@mui/material/Typography";
import { BeatLoader } from "react-spinners";
import s from "./Vacancies.module.css"


function Vacancies(props) {
  return (
    <div style={{ width: "70%", margin: "50px auto" }}>
      <Typography className={s.mainTitle}>
        Мы ждем вас в нашей команде
      </Typography>
      <Typography className={s.helpTitle}>
        Список открытых вакансий
      </Typography>
      <Grid container direction={"column"}>
        {
          props.vacancies
            ? props.vacancies.map(v =>
              <Grid item key={v.id} marginTop={5}>
                <VacancyItem
                  name={v.name}
                  job_requirements={v.job_requirements}
                  working_conditions={v.working_conditions}
                />
              </Grid>,
            )
            : <Grid container
                    width={"70%"}
                    margin={"auto"} justifyContent={"center"} alignItems={"center"}
                    minHeight={280}>
              <BeatLoader size={13} color={"#123abc"} />
            </Grid>
        }
      </Grid>
    </div>
  );
}

export default Vacancies;
