import React from "react";
import Grid from "@mui/material/Grid";
import { Field, reduxForm } from "redux-form";
import { AgreeCheckbox, CustomInput, MyCustomCapcha } from "./Fields";
import MKButton from "../../../../../components/MKButton";
import { phoneType, requiredField } from "../../../../../helpers/validators";
import s from "./Fields.module.css"

let ContactPhoneBackForm = ({anyTouched, ...props}) => {
  return (
    <form onSubmit={props.handleSubmit}>
      <Grid item textAlign={"center"} sx={{ paddingTop: 2 }}>
        <Field component={CustomInput}
               placeholder={"Введите ваше имя"}
               name={"name"}
               validate={[requiredField]}
        />
      </Grid>

      <Grid item textAlign={"center"}>
        <Field component={CustomInput}
               placeholder={"Введите ваш номер телефона"}
               validate={[requiredField, phoneType]}
               name={"phone"} />
      </Grid>

      <Grid textAlign={"center"} sx={{ paddingTop: "5px" }}>
        <Field component={AgreeCheckbox} name="agree" id="checkbox_id" validate={[requiredField]} />
        <label htmlFor="checkbox_id" className={s.checkboxLabel}>
          Согласие на обработку персональных данных
        </label>
      </Grid>

      { anyTouched && <Grid item>
        <Field component={MyCustomCapcha}
               sitekey={"6LeRDg8lAAAAALA3MJmthhcCWrhuxH1Ol6bqn7P3"} size={"normal"}
               style={{ paddingLeft: 15 }} name={"capcha"}
               validate={[requiredField]}
        />
      </Grid> }

      <Grid item textAlign={"center"}>
        <MKButton color="warning" type={"submit"} disabled={props.invalid} className={s.submitButton}>
          Отправить
        </MKButton>
      </Grid>
    </form>
  );
};

export default reduxForm({ form: "contactUs", touchOnChange: true, touchOnBlur: true })(ContactPhoneBackForm);