import { serviceApi } from "../../api/api";

const SET_SERVICES = "fire-site/service/SET_SERVICES";
const SET_SERVICE_DETAIL = "fire-site/service/SET_SERVICE_DETAIL";


const initialState = {
  services: undefined,
  serviceDetail: {},
};

const servicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SERVICES:
      return {
        ...state,
        services: action.services,
      };
    case SET_SERVICE_DETAIL:
      let serviceDetailCopy = { ...state.serviceDetail };
      serviceDetailCopy[action.service.id] = action.service;

      return {
        ...state,
        serviceDetail: serviceDetailCopy,
      };
    default:
      return state;
  }
};

export const setServices = (services) => ({ type: SET_SERVICES, services });
export const setServiceDetail = (service) => ({ type: SET_SERVICE_DETAIL, service });

export const getServices = () => async (dispatch) => {
  let services = [];

  try {
    services = await serviceApi.getServices();
  } catch (e) {
    console.error(e.message);
  }

  dispatch(setServices(services));
};

export const getService = (serviceId) => async (dispatch) => {
  let service = {};

  try {
    service = await serviceApi.getService(serviceId);
  } catch (e) {
    console.error(e.message);
  }
  dispatch(setServiceDetail(service));
};

export default servicesReducer;
