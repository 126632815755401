export const requiredField = value => {
  if (value) return undefined;
  return "Необходимо заполнить поле";
};

export const maxLength = length => value => {
  if (value && value.length <= length) return undefined;
  return `Must be ${length} character or less`;
};

export const phoneType = value => {
  if (value.match("^((8|\\+7)[\\- ]?)?(\\(?\\d{3}\\)?[\\- ]?)?[\\d\\- ]{7,10}$")) return undefined;
  return `Must be a phone number`;
};

export const maxLength15 = maxLength(15);
export const maxLength30 = maxLength(30);
export const maxLength50 = maxLength(50);

