import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MKButton from "../../../../../components/MKButton";
import { NavLink } from "react-router-dom";
import s from "./Cart.module.css"

function CartSum(props) {
  const { productsInfo, productsCart } = props;
  let cartSum = 0;

    for (let productId in productsCart) {
      if (productId in productsInfo) {
        cartSum += productsCart[productId] * (productsInfo[productId]["price"] - productsInfo[productId]["discount"]);
      }
    }

  return (
    <Grid width={"70%"} margin={"auto"} border={1} minHeight={"100px"}
          marginTop={5} borderColor={"#aaaaaa"} borderRadius={2}
          container justifyContent={"flex-end"} alignItems={"center"}
          columnSpacing={8} paddingRight={9} sx={{ backgroundColor: "#ffffff"}}
    >
      <Grid item>
        <Typography className={s.sumText}>
          Итого: {cartSum} руб.
        </Typography>
      </Grid>

      <Grid item>
        <NavLink to={"/shop/offer"}>
          <MKButton color={"primary"}>
            Оформить заказ
          </MKButton>
        </NavLink>
      </Grid>
    </Grid>
  );
}

export default CartSum;
