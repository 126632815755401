import React, {useState} from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Fade } from "@mui/material";
import MKButton from "../../../components/MKButton";
import s from "./Vacancies.module.css"

function VacancyItem(props) {
  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  let job_requirements = props.job_requirements.split('\n').map(
    (requirement, index) => <li key={index} className={s.vacancyDescription}>{requirement}</li>
  );
  let working_conditions = props.working_conditions.split('\n').map(
    (condition, index) => <li key={index} className={s.vacancyDescription}>{condition}</li>
  )

  return (
    <Box>
      <Typography className={s.vacancyName}>
        {props.name}
      </Typography>
      <MKButton onClick={handleChange} color={"info"} size={"small"}
                variant={"gradient"} className={s.vacancyButton}>
        Описание
      </MKButton>
      <Box>
        <Box sx={{ display: 'flex' }}>
          <Fade timeout={300} in={checked} style={{ display: checked ? 'block': 'none'}}>
            <div>
              <Typography fontWeight={"bold"} marginTop={2} fontSize={16}> Требования к кандидату: </Typography>
              <ul style={{ fontSize: "15px", paddingLeft: "15px"}} >{job_requirements}</ul>
              <Typography fontWeight={"bold"} marginTop={2} fontSize={16}> Условия труда: </Typography>
              <ul style={{ fontSize: "15px", paddingLeft: "15px"}} className={s.vacancyDescription}>{working_conditions}</ul>
            </div>
          </Fade>
        </Box>
      </Box>
    </Box>
  )
}

export default React.memo(VacancyItem);
