import React from "react";
import MobileCartTableRow from "./MobileCartTableRow";
import { connect } from "react-redux";
import { fullRemoveProduct } from "../../../../../../redux/reducers/shop-reducer";

class MobileCartTableRowContainer extends React.Component {
  render() {
    return <MobileCartTableRow {...this.props} />;
  }
}


export default connect(
  null, { fullRemoveProduct },
)(MobileCartTableRowContainer);
