import React from "react";
import Card from "@mui/material/Card";
import { CardActionArea, CardContent, CardMedia } from "@mui/material";
import Typography from "@mui/material/Typography";
import { NavLink } from "react-router-dom";
import styles from "./ServiceCard.module.css"

function ServiceCard(props) {
  return (
    <Card className={styles.card}>
      <CardActionArea className={styles.card_action_area} component={NavLink} to={`/services/${props.id}`}>
        <CardMedia
          component="img"
          className={styles.card_media}
          image={props.image}
          alt={props.title}
        />
        <CardContent className={styles.card_content}>
          <Typography className={styles.card_content__text} gutterBottom component="div">
            {props.title}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default ServiceCard;
