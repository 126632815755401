import React from "react";
import Grid from "@mui/material/Grid";
import ProductCard from "./ProductCard";
import { BeatLoader } from "react-spinners";
import Typography from "@mui/material/Typography";
import s from "./ListProducts.module.css";

function ListProducts(props) {
  const { products } = props;


  if (!products) {
    return (
      <Grid container width={"70%"} margin={"auto"} justifyContent={"center"} alignItems={"center"}
            minHeight={280}>
        <BeatLoader size={13} color={"#123abc"} />
      </Grid>
    );
  } else if (products.length === 0) {
    return (
      <Grid container width={"70%"} margin={"auto"} justifyContent={"center"} alignItems={"center"}
            minHeight={280}>
        <Typography className={s.noProductsTitle}>
          В данный момент нет товаров
        </Typography>
      </Grid>
    );
  }

  const productCards = Object.values(products).map(product => (
    <Grid key={product.id} item>
      <ProductCard
        productId={product.id}
        amount_status={product.amount_status}
        unit={product.unit}
        title={product.title}
        image={product.image}
        price={product.price}
        discount={product.discount}
        categoryId={product.category}
      />
    </Grid>
  ));


  return (
    <Grid container width={"70%"} margin={"auto"} justifyContent={"center"}
          columnSpacing={4} rowSpacing={5} minHeight={280}>
      {productCards}
    </Grid>
  );
}

export default ListProducts;
