import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import ContactInfo from "./ContactInfo";
import breakpoints from "../../../assets/theme/base/breakpoints";
import ContactPhoneBackContainer from "./ContactPhoneBack/ContactPhoneBackContainer";

function ContactUs(props) {
  const [perRow, setPerRow] = useState(2);

  useEffect(() => {
    // A function that sets the display state for the DefaultNavbarMobile.
    function displayContactUs() {
      if (window.innerWidth < breakpoints.values.lg) {
        setPerRow(1);
      } else {
        setPerRow(2);
      }
    }

    /**
     The event listener that's calling the displayMobileNavbar function when
     resizing the window.
     */
    window.addEventListener("resize", displayContactUs);

    // Call the displayMobileNavbar function to set the state with the initial value.
    displayContactUs();

    return () => window.removeEventListener("resize", displayContactUs);
  }, [perRow]);

  return (
    <>
      <Grid
        container
        sx={{ paddingBottom: "20px", paddingTop: "20px" }}
        margin="auto"
        alignItems={""}
        justifyContent={"left"}
      >
        <Grid item xs={perRow === 1 ? 12 : 3}>
          <ContactInfo />
        </Grid>
        <Grid item xs={perRow === 1 ? 12 : 6} paddingTop={perRow === 1 ? "40px" : "0px"}>
          <div>
            <ContactPhoneBackContainer />
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default ContactUs;
