import React from "react";
import Header from "./Header";
import { compose } from "redux";
import { connect } from "react-redux";
import { toggleMobileHeader, toggleMobileView } from "../../../../redux/reducers/header-reducer";
import breakpoints from "../../../../assets/theme/base/breakpoints";


class HeaderContainer extends React.Component {
  displayMobileNavbar() {
    if (window.innerWidth < breakpoints.values.lg) {
      this.props.toggleMobileView(true);
    } else {
      this.props.toggleMobileView(false);
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.displayMobileNavbar.bind(this));
    this.displayMobileNavbar();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.displayMobileNavbar.bind(this));
  }

  render() {
    const { links, showMobileHeader, mobileView, toggleMobileHeader } = this.props;
    return <Header links={links}
                   showMobileHeader={showMobileHeader}
                   mobileView={mobileView}
                   toggleMobileHeader={toggleMobileHeader} />;
  }
}


const mapStateToProps = (state) => ({
  mobileView: state.header.mobileView,
  showMobileHeader: state.header.showMobileHeader,
  links: state.header.links,
});

export default compose(
  connect(mapStateToProps, { toggleMobileView, toggleMobileHeader }),
)(HeaderContainer);