import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import WorkIcon from "@mui/icons-material/Work";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import ApartmentIcon from "@mui/icons-material/Apartment";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import s from "./MainFooter.module.css"
import {Link} from "react-router-dom";

function MainFooter(props) {

  return (
    <>
      <Grid
        container
        alignItems={"center"}
        justifyContent={"space-around"}
        width={"80%"}
        paddingTop={"10px"}
        margin={"auto"}
      >
        <Grid item height={80}>
          <Grid container alignItems={"center"} spacing={2} justifyContent={"center"} paddingLeft={1}>
            <Grid item textAlign={"center"} alignItems={"center"} justifyContent={"center"}>
              <WorkIcon height={100} fontSize={"large"} />
            </Grid>
            <Grid item height={100}>
              <Grid container direction={"column"} justifyContent={"start"} height={100}>
                <Grid item>
                  <Typography className={s.footerText}>ИНН: 3812992033</Typography>
                </Grid>
                <Grid item>
                  <Typography className={s.footerText}>КПП: 384901001</Typography>
                </Grid>
                <Grid item>
                  <Typography className={s.footerText}>ОГРН: 1223800016857</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item height={80}>
          <Grid container height={"100%"} direction={"column"} justifyContent={"space-evenly"} paddingLeft={5}>
            <Grid item>
              <Grid container>
                <PhoneAndroidIcon />
                <Typography className={s.footerText} sx={{ paddingLeft: 1 }}>+7 (901) 674-02-08</Typography>
              </Grid>
            </Grid>

            <Grid item>
              <Grid container>
                <ApartmentIcon />
                <Typography className={s.footerText} sx={{ paddingLeft: 1 }}> г Иркутск, мкр. Топкинский, д. 27а</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item height={40}>
          <Grid container height={"100%"} justifyContent={"center"} alignItems={"center"} columnSpacing={2}
                paddingBottom={2}>
            <Grid item>
              <WorkHistoryIcon fontSize={"large"} />
            </Grid>

            <Grid item>
              <Grid container direction={"column"}>
                <Grid item>
                  <Typography className={s.footerText}>Пн - Пт: 09:00 - 18:00</Typography>
                </Grid>

                <Grid item>
                  <Typography className={s.footerText}>Сб - Вс: Выходной</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container justifyContent={"space-between"} width={"70%"} margin={"auto"}
            paddingBottom="20px" paddingTop={"20px"}>
        <Grid item>
          <Typography color="#555555" className={s.footerHelp}>
            2023 © ООО "БПК"
          </Typography>
        </Grid>

        <Grid item>
          <Typography className={s.footerHelp}>
            <Link to='/privacy-policy'>Политика обработки персональных данных</Link>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

export default MainFooter;
