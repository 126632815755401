import React from 'react';
import ContactUs from "./components/ContactUs/ContactUs";
import MainFooter from "./components/MainFooter/MainFooter";
import ContactMap from "./components/ContactUs/ContactMap";
import HeaderContainer from "./components/Header/Header/HeaderContainer";

function Contacts(props) {
  document.title = "БПК | Контакты";

  return (
    <>
      <HeaderContainer />
      <div style={{ paddingTop: "30px", paddingBottom: "30px", width: "70%", margin: "auto"}}>
          <ContactMap />
      </div>

      <div className={"white"}>
        <ContactUs />
      </div>
      <div>
        <MainFooter />
      </div>
    </>
  )
}

export default Contacts;
