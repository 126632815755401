import React, { useEffect } from "react";
import ShopCategory from "./ShopCategory";
import { connect } from "react-redux";
import { getCategories, getProductsByCategory } from "../../../redux/reducers/shop-reducer";
import { useParams } from "react-router-dom";

const ShopCategoryContainer = (props) => {
  const { categories, productsList, getProductsByCategory, getCategories } = props;
  const { categoryId } = useParams();

  useEffect(() => {
    getProductsByCategory(categoryId);
    if (!categories || !(categoryId in categories)) {
      getCategories();
    }
  }, [categories, categoryId, getCategories, getProductsByCategory]);

  const products = productsList ? productsList[categoryId] : undefined
  const category = categories ? categories[categoryId] : undefined

  return <ShopCategory products={products} category={category}/>;
};
const mapStateToProps = (state) => ({
  productsList: state.shop.productsList,
  categories: state.shop.categories,
});

export default connect(mapStateToProps, { getProductsByCategory, getCategories })(ShopCategoryContainer);