import React from "react";
import { CardActionArea, CardContent, CardMedia, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import { NavLink } from "react-router-dom";
import s from "./CategoryCard.module.css"


function CategoryCard(props) {
  return (
    <Card sx={{ height: 300, width: 200 }}>
      <CardActionArea component={NavLink} to={`/shop/${props.id}`} sx={{ height: "100%" }}>
        <Grid container direction={"column"}
              paddingTop={2}
              justifyContent={"center"}
              alignItems={"center"}
              height={"100%"}
        >
          <Grid item xs={6}>
            <CardMedia
              component="img"
              sx={{
                width: "100%", marginX: 0, marginY: "auto",
                objectFit: "cover", maxHeight: "150px", height: "auto",
              }}
              image={props.image}
              alt={props.title}
            />
          </Grid>

          <Grid item xs paddingTop={3}>
            <CardContent>
              <Typography component="div" className={s.cardTitle}>
                {props.title}
              </Typography>
            </CardContent>
          </Grid>
        </Grid>


      </CardActionArea>
    </Card>
  );
}

export default CategoryCard;
