import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import MainFooter from "../../components/MainFooter/MainFooter";
import Parser from "html-react-parser";
import HeaderContainer from "../../components/Header/Header/HeaderContainer";
import s from "./ServiceItemPage.module.css";
import ContactUs from "../../components/ContactUs/ContactUs";

function ServiceItemPage(props) {
  const { service } = props;

  if (!service) {
    return (<></>);
  }

  return (
    <>
      <HeaderContainer />
      <div style={{ textAlign: "center", marginTop: 50, marginLeft: "auto", marginRight: "auto", maxWidth: "800px"}}>
        <Typography className={s.title}> {service.title} </Typography>
      </div>
      <Grid className={s.content}>
        {Parser(service.description_html)}
      </Grid>
      <div className="white" style={{ marginTop: "40px"}}>
        <ContactUs />
      </div>
      <div style={{ marginTop: "40px" }}>
        <MainFooter />
      </div>
    </>
  );
}

export default ServiceItemPage;
