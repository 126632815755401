import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ReCAPTCHA from "react-google-recaptcha";
import MKButton from "../../../../../components/MKButton";
import { BeatLoader } from "react-spinners";
import { NavLink } from "react-router-dom";
import s from "../OfferTabs.module.css";

const LastTab = (props) => {
  const { offerForm, changeFormFieldByValue, cartSum, submitOfferForm } = props;

  function onChangeCapcha(value) {
    changeFormFieldByValue("capcha", value);
  }

  const onSubmit = () => {
    if (offerForm.address === "" || offerForm.addressIndex === "") {
      changeFormFieldByValue("currentTab", 0);
      return;
    } else if (offerForm.email === "" || offerForm.phone === "" || offerForm.fio === "") {
      changeFormFieldByValue("currentTab", 1);
      return;
    }

    if (offerForm.isLoading) {
      return;
    }
    submitOfferForm();
  };

  if (offerForm.isLoading === false) {
    return (
      <Grid container direction={"column"} alignItems={"center"} justifyContent={"center"} height={280}
            rowSpacing={2} className={s.lastTab}>
        <Grid item>
          <Typography className={s.lastTabSum} component={"span"} fontSize={25}>
            Итого: {cartSum} р.
          </Typography>
        </Grid>

        <Grid item width={"100px"} height={"80px"}>
          <ReCAPTCHA
            size={"normal"}
            sitekey="6LeRDg8lAAAAALA3MJmthhcCWrhuxH1Ol6bqn7P3"
            onChange={onChangeCapcha}
            style={{ position: "relative", right: "60%", transform: "scale(0.8)", transformOrigin: "0 0" }}
          />
        </Grid>

        <Grid item>
          <MKButton color={"success"} onClick={onSubmit} size={"small"} disabled={offerForm.capcha === ""}>
            Подтвердить
          </MKButton>
        </Grid>
      </Grid>
    );
  } else if (offerForm.serverAnswer === undefined) {
    return (
      <Grid container justifyContent={"center"} alignItems={"center"} height={"100%"}>
        <BeatLoader size={20} color={"#123abc"} style={{ position: "relative", top: "110px" }} />
      </Grid>
    );
  } else if (offerForm.serverAnswer === true) {
    return (
      <Grid container direction={"column"} alignItems={"center"} justifyContent={"center"} height={280}>
        <Grid item>
          <Typography className={s.lastTabSum} component={"span"} color={"#444444"}>
            Спасибо за покупку! Мы с вами свяжемся!
          </Typography>
        </Grid>

        <Grid item component={NavLink} to={"/"}>
          <MKButton color={"success"} size={"small"}>
            Вернуться на сайт
          </MKButton>
        </Grid>
      </Grid>
    );
  } else if (offerForm.serverAnswer === false) {
    return (
      <Grid container direction={"column"} alignItems={"center"} justifyContent={"center"} height={280}>
        <Grid item>
          <Typography className={s.lastTabSum} component={"span"} color={"#444444"}>
            Произошла ошибка. Пожалуйста, попробуйте позже...
          </Typography>
        </Grid>

        <Grid item component={NavLink} to={"/"}>
          <MKButton color={"warning"} size={"small"}>
            Вернуться на сайт
          </MKButton>
        </Grid>
      </Grid>
    );
  }
};

export default LastTab;