import React from "react";
import Typography from "@mui/material/Typography";
import s from "./ContactInfo.module.css";

function ContactMap(props) {
  return (
    <>
      <Typography fontSize={30} textAlign={"center"} paddingBottom={2} className={s.title}>
        Карта
      </Typography>
      <script src="https://api-maps.yandex.ru/2.1/?lang=ru_RU&amp;apikey=<ваш API-ключ>" type="text/javascript" />
      <script type="text/javascript" charSet="utf-8" async
              src="https://api-maps.yandex.ru/services/constructor/1.0/js/?um=constructor%3A444e879b37dadf1ae9e9aafc173c1305c2f02dbf3fa581daa92c0be40232a6f8&amp;width=100%25&amp;height=400&amp;lang=ru_RU&amp;scroll=true" />
      <iframe title={"map"}
              src="https://yandex.com/map-widget/v1/?um=constructor%3A444e879b37dadf1ae9e9aafc173c1305c2f02dbf3fa581daa92c0be40232a6f8&amp;source=constructor"
              width="100%" height="300" />
      <script>

      </script>
    </>
  );
}

export default ContactMap;
