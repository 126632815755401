import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import HumanPNG from "../../../assets/images/человек3.png";
import s from "./AboutUs.module.css";


function AboutUs(props) {
  return (
    <>
      <Typography textAlign={"center"} className={s.decorativeAmaticTitle} fontSize={50} fontWeight={"bold"}>
        О нас
      </Typography>

      <Grid container width="80%" margin="auto" alignItems={"center"} >
        <Grid item xs={3.3}>
          <img alt="Команда"
               className={s.mainImage}
               src={HumanPNG} />
        </Grid>

        <Grid item maxWidth={680}>
          <Grid container justifyContent={"start"} direction={"column"} rowSpacing={1}>
            <Grid item>
            </Grid>
            <Grid item display={"inline"}>
              <Typography fontSize={19} component={"div"} className={s.contentFont}>
                <Box fontWeight="fontWeightMedium" display="inline">Байкальская противопожарная компания </Box>
                начала свою деятельность в сентябре 2022 г. для того, чтобы Вам было удобно и безопасно.
                Наша компания предоставляет услуги по пожарной безопасности по всей Иркутской области в соответствии с
                современными запросами и нормативной базой.
                <Box><br />
                  Если Вы заинтересованы в качественной защите от пожара и
                  профессиональном обслуживании специального оборудования, наберите
                  контактный номер нашей компании. Мы предоставим консультацию в любое удобное для Вас время.
                </Box>
              </Typography>
            </Grid>

          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default AboutUs;
