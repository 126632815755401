import { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "assets/theme";
import Contacts from "./pages/Contacts";
import MainShop from "./pages/Shop/MainShop/MainShop";
import MainPage from "./pages/MainPage";
import ServicePage from "./pages/ServicePage/ServicePage";
import VacanciesPage from "./pages/Vacancies/VacanciesPage";
import ConfirmPurchase from "./pages/Shop/ConfirmPurchase/ConfirmPurchase";
import PrivacyPolicy from "./pages/components/PrivacyPolicy/PrivacyPolicy";
import "./App.css";
import ServiceItemPageContainer from "./pages/ServicePage/ServiceItemPage/ServiceItemPageContainer";
import CartContainer from "./pages/Shop/Cart/CartContainer";
import ShopCategoryContainer from "./pages/Shop/ShopCategory/ShopCategoryContainer";


export default function App(props) {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: "instant" });
  }, [pathname]);

  return (
    <div style={{ minWidth: 375 }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/shop" element={<MainShop />} />
          <Route path="/shop/cart" element={<CartContainer />} />
          <Route path="/shop/offer" element={<ConfirmPurchase />} />
          <Route path="/shop/:categoryId" element={<ShopCategoryContainer />} />
          <Route path="/services" element={<ServicePage />} />
          <Route path="/services/:serviceId" element={<ServiceItemPageContainer />} />
          <Route path="/" element={<MainPage />} />
          <Route path="/vacancies"
                 element={<VacanciesPage />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy/>}/>
        </Routes>
      </ThemeProvider>
    </div>
  );
}
