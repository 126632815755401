import React from "react";
import { ListItem, ListItemButton, ListItemText } from "@mui/material";

const MobileLink = ({ name, isLast, component, ...rest }) => {
  return (
    <ListItem key={name}>
      <ListItemButton component={component} {...rest} sx={{paddingTop: 2}}>
        <ListItemText primary={name} />
      </ListItemButton>
    </ListItem>
  );
};

export default MobileLink;