import React from "react";
import ContactUs from "../components/ContactUs/ContactUs";
import MainFooter from "../components/MainFooter/MainFooter";
import VacanciesContainer from "../components/Vacancies/VacanciesContainer";
import HeaderContainer from "../components/Header/Header/HeaderContainer";

function VacanciesPage() {
  document.title = "БПК | Вакансии";

  return (
    <>
      <HeaderContainer />
      <div style={{ minHeight: 240 }}>
        <VacanciesContainer />
      </div>
      <div className={"white"}>
        <ContactUs />
      </div>
      <div>
        <MainFooter />
      </div>
    </>
  );
}

export default VacanciesPage;
