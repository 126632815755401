import { vacancyApi } from "../../api/api";

const SET_VACANCIES = "fire-site/vacancy/SET-VACANCIES";


const initialState = {
  vacancies: undefined,
};

const vacancyReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_VACANCIES:
      return {
        ...state,
        vacancies: action.vacancies,
      };
    default:
      return state;
  }
};

export const setVacancies = (vacancies) => ({ type: SET_VACANCIES, vacancies });

export const getVacancies = () => async (dispatch) => {
  let vacancies = [];

  try {
    vacancies = await vacancyApi.getVacancies();
  } catch (e) {
    console.error(e.message)
  }
  dispatch(setVacancies(vacancies))
}

export default vacancyReducer;