import { productCookies } from "../../api/cookies";
import { shopApi } from "../../api/api";

const SET_PRODUCTS = "fire-site/shop/SET_PRODUCTS";
const TOGGLE_INIT_PRODUCTS = "fire-site/shop/TOGGLE_INIT_PRODUCTS";
const SET_PRODUCTS_INFO = "fire-site/shop/SET_PRODUCTS_INFO";
const SET_CATEGORIES = "fire-site/shop/SET_CATEGORIES";
const ADD_PRODUCT_LIST = "fire-site/shop/ADD_PRODUCT_LIST";

const CHANGE_FORM_FIELD = "fire-site/shop/CHANGE_FORM_FIELD";
const CLEAR_FORM = "fire-site/shop/CLEAR_FORM";


const initialState = {
  productsCart: {},
  isProductsInit: false,
  productsInfo: undefined,
  categories: undefined,
  productsList: [],

  offerForm: {
    currentTab: 0, address: "", addressIndex: "", email: "", phone: "", fio: "", comment: "", capcha: "",

    isLoading: false, serverAnswer: undefined,
  },
};

const shopReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRODUCTS:
      return {
        ...state, productsCart: action.products,
      };
    case TOGGLE_INIT_PRODUCTS:
      return {
        ...state, isProductsInit: action.status,
      };

    case SET_PRODUCTS_INFO:
      return {
        ...state, productsInfo: action.productsInfo,
      };

    case SET_CATEGORIES:
      return {
        ...state, categories: action.categories,
      };

    case ADD_PRODUCT_LIST:
      let productsList = { ...state.productsList };
      productsList[action.categoryId] = action.products;

      return {
        ...state, productsList,
      };

    case CHANGE_FORM_FIELD:
      let stateCopy = { ...state, offerForm: { ...state.offerForm } };
      stateCopy.offerForm[action.field] = action.value;

      return stateCopy;

    case CLEAR_FORM:
      return {
        ...state, offerForm: { ...initialState.offerForm },
      };

    default:
      return state;
  }
};

export const setProducts = (products) => ({ type: SET_PRODUCTS, products });
export const toggleInitProducts = (status) => ({ type: TOGGLE_INIT_PRODUCTS, status });
export const setProductsInfo = (productsInfo) => ({ type: SET_PRODUCTS_INFO, productsInfo });
export const setCategories = (categories) => ({ type: SET_CATEGORIES, categories });
export const addProductList = (categoryId, products) => ({ type: ADD_PRODUCT_LIST, categoryId, products });

export const changeFormField = (field, value) => ({ type: CHANGE_FORM_FIELD, field, value });
export const clearForm = () => ({ type: CLEAR_FORM });


export const addProduct = (productId) => (dispatch) => {
  const products = productCookies.addProduct(productId);
  dispatch(setProducts(products));
};

export const removeProduct = (productId, minValue = 0) => (dispatch) => {
  const products = productCookies.removeProduct(productId, minValue);
  dispatch(setProducts(products));
};

export const fullRemoveProduct = (productId) => (dispatch) => {
  const products = productCookies.fullRemoveProduct(productId);
  dispatch(setProducts(products));
};

export const clearProducts = () => (dispatch) => {
  const products = productCookies.clearProducts();
  dispatch(setProducts(products));
};

export const initProducts = () => (dispatch) => {
  const products = productCookies.getProducts();
  dispatch(setProducts(products));
  dispatch(toggleInitProducts(true));
};

export const getProductsInfo = () => async (dispatch) => {
  const products = productCookies.getProducts();

  try {
    const data = await shopApi.getProductsInfo(products);

    dispatch(setProductsInfo(data));

    let productsArray = Object.entries(products);
    productsArray = productsArray.filter(([productId]) => productId in data);
    dispatch(setProducts(Object.fromEntries(productsArray)));
  } catch (e) {
    console.error(e.message);
  }

};

export const getCategories = () => async (dispatch) => {
  try {
    let data = await shopApi.getCategories();

    data = data.map(category => [category.id, category]);
    data = Object.fromEntries(data);
    dispatch(setCategories(data));
  } catch (e) {
    console.error(e.message);
  }
};

export const getProductsByCategory = (categoryId) => async (dispatch) => {
  try {
    const data = await shopApi.getProductsByCategory(categoryId);
    dispatch(addProductList(categoryId, data));
  } catch (e) {
    console.error(e.message);
  }
};

export const submitOfferForm = () => async (dispatch, getState) => {
  const shopState = getState().shop;

  try {
    dispatch(changeFormField("isLoading", true));
    const data = await shopApi.submitOfferForm(shopState.offerForm, shopState.productsCart);

    dispatch(changeFormField("serverAnswer", data["status"] === "ok"));

    if (data["status"] === "ok") {
      dispatch(clearProducts());
    }
  } catch (e) {
    dispatch(changeFormField("serverAnswer", false));
  }
};

export default shopReducer;