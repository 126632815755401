import React from "react";
import ContactUs from "../../components/ContactUs/ContactUs";
import MainFooter from "../../components/MainFooter/MainFooter";
import Typography from "@mui/material/Typography";
import HeaderContainer from "../../components/Header/Header/HeaderContainer";
import ShopCartHeaderContainer from "../../components/Shop/ShopCartHeader/ShopCartHeaderContainer";
import ListCategoriesContainer from "../../components/Shop/Categories/ListCategoriesContainer";
import s from "./MainShop.module.css"

function MainShop(props) {
  document.title = "БПК | Магазин";

  return (
    <>
      <HeaderContainer />
      <div style={{ marginTop: "30px", marginBottom: "30px" }}>
      <ShopCartHeaderContainer />
        <Typography className={s.pageTitle}>
          Магазин
        </Typography>
        <ListCategoriesContainer />
      </div>
      <div className="white">
        <ContactUs />
      </div>
      <div>
        <MainFooter />
      </div>
    </>
  );
}

export default MainShop;
