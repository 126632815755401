import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import { CardContent, CardMedia } from "@mui/material";
import s from "./OurPluses.module.css"
import OurPlusesImage1 from "../../../assets/images/OurPluses1.jpg"
import OurPlusesImage2 from "../../../assets/images/OurPluses2.png"
import OurPlusesImage3 from "../../../assets/images/OurPluses3.png"


function OurPluses(props) {
  return (
    <Grid container width="75%" alignItems={"left"} margin={"auto"}
          paddingY={3} direction={"column"}>
      <Grid item textAlign={"center"}>
        <Typography className={s.decorativeAmaticTitle} fontSize={50} fontWeight={"bold"}>
          Наши преимущества
        </Typography>
      </Grid>

      <Grid item paddingTop={3}>
        <Grid container columnSpacing={1} rowSpacing={4} justifyContent={"space-around"}>
          <Grid item>
            <Card className={s.ourPlusesCard}>
              <CardMedia
                className={s.ourPlusesImage}
                image={OurPlusesImage1}
              />
              <CardContent>
                <Typography component="div" className={s.outPlusesListTitle}>
                  Цифровое обслуживание
                </Typography>
                <Typography className={s.ourPlusesContent} paddingLeft={2} component={"div"}>
                  <ul className={s.ourPlusesGrid}>
                    <li>Ведение электронного журнала технического обслуживания</li>
                    <li>Процесс проведения технического обсуживания полностью автоматизирован</li>
                    <li>Доступ в личный кабинет с возможностью выгрузить данные по Техническому обслуживанию</li>
                    <li>Электронный документооборот (Диадок)</li>
                  </ul>
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item>
            <Card className={s.ourPlusesCard}>
              <CardMedia
                className={s.ourPlusesImage}
                image={OurPlusesImage2}
              />
              <CardContent>
                <Typography className={s.outPlusesListTitle} component="div">
                  Индивидуальный подход
                </Typography>
                <Typography className={s.ourPlusesContent} paddingLeft={2} component={"div"}>
                  <ul className={s.ourPlusesGrid}>
                    <li>Поможем с ГосПожНадзором</li>
                    <li>Устраним нарушения и сделаем объект безопасным</li>
                    <li>Никаких скрытых доплат, все прозрачно</li>
                    <li>Бесплатный выезд специалиста в день обращения</li>
                    <li>Аренда противопожарного оборудования</li>
                    <li>Выбор удобной системы оплаты</li>
                    <li>Гарантия 12 мес. на выполненные работы</li>
                  </ul>
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item>
            <Card className={s.ourPlusesCard}>
              <CardMedia
                className={s.ourPlusesImage}
                image={OurPlusesImage3}
              />
              <CardContent>
                <Typography component="div" className={s.outPlusesListTitle}>
                  Наша команда
                </Typography>
                <Typography className={s.ourPlusesContent} paddingLeft={2} component={"div"}>
                  <ul className={s.ourPlusesGrid}>
                    <li>Инженеры  со специальным образованием и наличием всех необходимых допусков</li>
                    <li>Наши специалисты постоянно развивают свои профессиональные качества</li>
                  </ul>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

      </Grid>


    </Grid>
  );
}

export default OurPluses;
