import React from "react";
import Grid from "@mui/material/Grid";
import CartTableRowContainer from "./CartTableRow/CartTableRowContainer";

function CartTable(props) {
  const {productsInfo, productsCart} = props;

  let productRows = [];

  for (let productId in productsCart) {
    productRows.push(<CartTableRowContainer productInfo={productsInfo[productId]}
                                   productId={productId}
                                   amount={productsCart[productId]}
                                   key={productId}
    />);
  }


  return (
    <Grid container direction={"column"} sx={{ marginTop: 3, backgroundColor: "#ffffff"}} width={"70%"}
          margin={"auto"} borderRadius={2} border={1} borderColor={"#cccccc"} >
      {productRows}
    </Grid>
  )
}

export default CartTable;
