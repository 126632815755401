import { NavLink } from "react-router-dom";

const TOGGLE_MOBILE_HEADER = "fire-site/header/TOGGLE_MOBILE_HEADER";
const TOGGLE_MOBILE_VIEW = "fire-site/header/TOGGLE_MOBILE_VIEW";


const initialState = {
  mobileView: false,
  showMobileHeader: false,
  links: [
    { name: "Главная", to: "/", component: NavLink },
    { name: "Услуги", to: "/services", component: NavLink },
    { name: "Магазин", to: "/shop", component: NavLink },
    { name: "Вакансии", to: "/vacancies", component: NavLink },
    { name: "Контакты", to: "/contacts", component: NavLink },
    { name: "Вход", href: "https://бпк38.рф/admin", component: "a", target: "_blank" },
  ],
};

const headerReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_MOBILE_HEADER:
      return {
        ...state,
        showMobileHeader: action.status,
      };
    case TOGGLE_MOBILE_VIEW:
      return {
        ...state,
        mobileView: action.status,
      };
    default:
      return state;
  }
};

export const toggleMobileView = (status) => ({ type: TOGGLE_MOBILE_VIEW, status });
export const toggleMobileHeader = (status) => ({ type: TOGGLE_MOBILE_HEADER, status });

export default headerReducer;
