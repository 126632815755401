import React from "react";
import ButtonAmount from "./ButtonAmount";
import { compose } from "redux";
import { connect } from "react-redux";
import { addProduct, removeProduct } from "../../../../redux/reducers/shop-reducer";

class ButtonAmountContainer extends React.Component {
  render() {
    return <ButtonAmount {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  productsCart: state.shop.productsCart,
});

export default compose(
  connect(mapStateToProps, { addProduct, removeProduct }),
)(ButtonAmountContainer);