import axios from "axios";

axios.defaults.baseURL = "https://бпк38.рф/api";

export const vacancyApi = {
  getVacancies: () => axios.get("/site/vacancies/").then(response => response.data),
};

export const serviceApi = {
  getServices: () => axios.get("/site/services").then(response => response.data),
  getService: (serviceId) => axios.get(`/site/services/${serviceId}/`).then(response => response.data),
};

export const shopApi = {
  getProductsInfo: (products) => axios.post("/shop/get-products/", { ...products }).then(response => response.data),
  getCategories: () => axios.get("/shop/product_category/").then(response => response.data),
  getProductsByCategory: (categoryId) => {
    const params = {
      category: categoryId,
    };

    return axios.get("/shop/product/", { params }).then(response => response.data);
  },

  submitOfferForm: (form, products) => {
    const data = {
      ...form, products
    }
    return axios.post("/shop/confirm/", data).then(response => response.data)
  }
};

export const contactApi = {
  sendContact: (name, phone, capcha) => {
    const data = {
      name, phone, capcha
    }

    return axios.post('/site/send-feedback/', data).then(response => response.data)
}
}
