import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MKButton from "../../../../../../components/MKButton";
import ButtonAmountContainer from "../../../buttons/ButtonsAmountContainer";
import s from "./TableRow.module.css";

function CartTableRow(props) {
  let { productId, productInfo, amount, fullRemoveProduct } = props;

  if (!(productInfo && amount)) {
    return <></>;
  }
  const unitCost = productInfo["price"] - productInfo["discount"];
  const productCost = amount * unitCost;
  const unit = productInfo["unit"];
  const imageSrc = productInfo["image"];
  const title = productInfo["title"];


  return (
    <Grid container alignItems={"center"} boxShadow={"1"} paddingBottom={2}>
      <Grid item>
        <Grid container>
          <Grid item paddingTop={2} margin={"auto"}>
            <img alt={"Товар"}
                 src={imageSrc}
                 className={s.productImage}
            />
          </Grid>

          <Grid item height={"fit-content"} margin={"auto"}>
            <Typography className={s.productTitle} textAlign={"center"}>
              {title}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item margin={"auto"}>
        <Grid container justifyContent={"center"} rowSpacing={2}
              alignItems={"center"} direction={"column"}>
          <Grid item>
            <Typography className={s.productCost}>
              {productCost ? `${productCost} руб.` : "По запросу"}
            </Typography>
          </Grid>

          <Grid item>
            <Grid container direction={"column"} alignItems={"center"} rowSpacing={2}>
              <Grid item>
                <ButtonAmountContainer productId={productId} minValue={1} unit={unit} />
              </Grid>

              <Grid item>
                <MKButton color={"primary"} size={"small"} variant={"gradient"}
                          onClick={() => fullRemoveProduct(productId)} >
                  Удалить
                </MKButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CartTableRow;
