import React  from "react";
import Grid from "@mui/material/Grid";
import ServiceCard from "../ServiceCard/ServiceCard";
import { BeatLoader } from "react-spinners";

function ServicesList(props) {


  if (!props.services) {
    return (
      <Grid container width={"70%"} margin={"auto"} justifyContent={"center"} alignItems={"center"}
            minHeight={280}>
        <BeatLoader size={20} color={"#123abc"} />
      </Grid>
    );
  }


  const cards = props.services.map(service => (
      <Grid key={service.id} item height={"100%"}>
        <ServiceCard
          title={service.title}
          image={service.image}
          id={service.id}
        />
      </Grid>
    ),
  );

  return (
    <Grid container
          width="80%"
          margin={"auto"}
          paddingTop={5}
          alignItems={"center"}
          justifyContent={"center"}
    >
      {cards}
    </Grid>
  );
}

export default ServicesList;
