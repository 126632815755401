import React from "react";
import ShopCartHeader from "./ShopCartHeader";
import { compose } from "redux";
import { connect } from "react-redux";
import { initProducts } from "../../../../redux/reducers/shop-reducer";

class ShopCartHeaderContainer extends React.Component {

  componentDidMount() {
    if (!this.props.isProductsInit) {
      this.props.initProducts();
    }
  }

  render() {
    return <ShopCartHeader productsCart={this.props.productsCart}/>;
  }
}

const mapStateToProps = (state) => ({
  productsCart: state.shop.productsCart,
  isProductsInit: state.shop.isProductsInit,
});

export default compose(
  connect(mapStateToProps, { initProducts }),
)(ShopCartHeaderContainer);