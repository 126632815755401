import React from "react";
import { connect } from "react-redux";
import Vacancies from "./Vacancies";
import { getVacancies } from "../../../redux/reducers/vacancy-reducer";


class VacanciesContainer extends React.Component {
  componentDidMount() {
    this.props.getVacancies();
  }

  render() {
    return <Vacancies {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  vacancies: state.vacancyPage.vacancies,
});

export default connect(mapStateToProps, { getVacancies })(VacanciesContainer);
