import React from "react";
import OfferTabs from "./OfferTabs";
import { connect } from "react-redux";
import {
  changeFormField, clearForm,
  getProductsInfo,
  initProducts,
  submitOfferForm,
} from "../../../../redux/reducers/shop-reducer";
import Grid from "@mui/material/Grid";
import { BeatLoader } from "react-spinners";

class OfferTabsContainer extends React.Component {
  componentDidMount() {
    if (!this.props.isProductsInit) {
      this.props.initProducts();
    }
    this.props.getProductsInfo();
  }

  componentWillUnmount() {
    this.props.clearForm()
  }

  changeFormFieldByEvent = (field, event) => {
    const value = event.currentTarget.value;
    this.props.changeFormField(field, value);
  };

  changeFormFieldByValue = (field, value) => {
    this.props.changeFormField(field, value);
  };

  render() {
    if (!this.props.productsCart || !this.props.productsInfo) {
      return (
        <Grid container width={"70%"} margin={"auto"} justifyContent={"center"} alignItems={"center"}
              minHeight={280}>
          <BeatLoader size={13} color={"#123abc"} />
        </Grid>
      );
    }

    let cartSum = 0;

    for (let productId in this.props.productsCart) {
      if (productId in this.props.productsInfo) {
        cartSum += this.props.productsCart[productId] * (this.props.productsInfo[productId]["price"] - this.props.productsInfo[productId]["discount"]);
      }
    }

    return <OfferTabs cartSum={cartSum}
                      changeFormFieldByEvent={this.changeFormFieldByEvent}
                      changeFormFieldByValue={this.changeFormFieldByValue}
                      offerForm={this.props.offerForm}
                      submitOfferForm={this.props.submitOfferForm} />;
  }
}

const mapStateToProps = (state) => ({
  isProductsInit: state.shop.isProductsInit,
  productsInfo: state.shop.productsInfo,
  productsCart: state.shop.productsCart,
  offerForm: state.shop.offerForm,
});


export default connect(
  mapStateToProps,
  { changeFormField, initProducts, getProductsInfo, submitOfferForm, clearForm },
)(OfferTabsContainer);