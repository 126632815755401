import React from "react";
import M from "materialize-css";
import MainFooter from "./components/MainFooter/MainFooter";
import ContactUs from "./components/ContactUs/ContactUs";
import AboutUs from "./components/MainPage/AboutUs";
import OurPluses from "./components/MainPage/OurPluses";
import HeaderContainer from "./components/Header/Header/HeaderContainer";

function MainPage(props) {
  document.title = "БПК | Главная";

  return (
    <>
      <HeaderContainer />
      <div className="section" style={{ paddingTop: "30px", paddingBottom: "30px" }}>
        <AboutUs />
      </div>
      <div className="white">
        <OurPluses />
      </div>
      <div>
        <ContactUs />
      </div>
      <div className="white">
        <MainFooter />
      </div>
    </>
  );
}

export default MainPage;
