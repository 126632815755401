import React from "react";
import CartTableRow from "./CartTableRow";
import { connect } from "react-redux";
import { fullRemoveProduct } from "../../../../../../redux/reducers/shop-reducer";

class CartTableRowContainer extends React.Component {
  render() {
    return <CartTableRow {...this.props} />;
  }
}


export default connect(
  null, { fullRemoveProduct },
)(CartTableRowContainer);
