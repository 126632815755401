import React from "react";
import Badge from "@material-ui/core/Badge";
import { withStyles } from "@material-ui/core/styles";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Grid from "@mui/material/Grid";
import { NavLink } from "react-router-dom";

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}))(Badge);

export default function ShopCartHeader(props) {
  const {productsCart} = props;

  let productsAmount = 0;
  for (let productId in productsCart) {
    if (productsCart[productId] > 0) {
      productsAmount += 1;
    }
  }

  return (
    <Grid justifyContent={"right"} container paddingRight={4}>
      <Grid item>
        <NavLink to={"/shop/cart"} style={{ color: "#000000"}}>
          <StyledBadge badgeContent={productsAmount} color="secondary" overlap={"rectangular"}>
            <ShoppingCartIcon fontSize={"large"} />
          </StyledBadge>
        </NavLink>
      </Grid>
    </Grid>

  );
}