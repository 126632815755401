import React from "react";
import Input from "@material-ui/core/Input";
import { Checkbox } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import s from "./Fields.module.css";

const ErrorSign = (props) => {
    return <ErrorOutlineIcon className={s.error_sign}  />
}


export const CustomInput = ({ placeholder, style, input, meta, ...props }) => {
  const hasError = meta.touched && meta.error && !meta.active;


  return (
    <div style={{ position: "relative" }}>
      <Input value={input.value}
             style={style}
             placeholder={placeholder}
             className={`${s.input_field} ` + (hasError ? s.error_input : "")}
             {...input} {...props}
             onChange={event => {
               input.onChange(event.currentTarget.value);
             }} />
      {hasError && <ErrorSign message={meta.error} />}
    </div>
  );
};


export const AgreeCheckbox = ({ id, input, meta, ...props }) => {
  return (
    <>
      <Checkbox id={id} value={input.value} {...input}
                onChange={(event, checked) => {
                  input.onChange(checked);
                }} />
    </>);
};

export const MyCustomCapcha = ({ sitekey, size, style, ...props }) => {
  return <ReCAPTCHA sitekey={sitekey} size={size}
                    value={props.input.value}
                    style={style}
                    onChange={value => {
                      props.input.onChange(value);
                    }}
  />;
};