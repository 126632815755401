import React, { useEffect } from "react";
import ServiceItemPage from "./ServiceItemPage";
import { connect } from "react-redux";
import { compose } from "redux";
import { getService } from "../../../redux/reducers/service-reducer";
import { useParams } from "react-router-dom";

const ServiceItemPageContainer = (props) => {
  const {serviceDetail, getService} = props;
  const { serviceId } = useParams();

  useEffect(async () => {
    await getService(serviceId)
  }, [getService, serviceId]);


  return <ServiceItemPage service={serviceDetail[serviceId]}/>;
};

const mapStateToProps = (state) => ({
  serviceDetail: state.servicePage.serviceDetail,
});

export default compose(
  connect(mapStateToProps, { getService }),
)(ServiceItemPageContainer);