import React from "react";
import Typography from "@mui/material/Typography";
import MainFooter from "../components/MainFooter/MainFooter";
import ContactUs from "../components/ContactUs/ContactUs";
import ServiceListContainer from "../components/Services/ServiceList/ServiceListContainer";
import HeaderContainer from "../components/Header/Header/HeaderContainer";
import s from "./ServicePage.module.css";

function ServicePage(props) {
  document.title = "БПК | Услуги";

  return (
    <>
      <HeaderContainer />
      <Typography className={s.title}>
        Наши услуги
      </Typography>

      <div className="section">
        <div className="row container">
          <ServiceListContainer />
        </div>
      </div>

      <div className="white" style={{ marginTop: "30px" }}>
        <ContactUs />
      </div>

      <div>
        <MainFooter />
      </div>
    </>
  );
}

export default ServicePage;
