import React from "react";
import Grid from "@mui/material/Grid";
import Link from "./Link/Link";


const DesktopHeader = (props) => {
  const {links} = props

  return (
    <Grid item>
      <Grid container height={"50px"}>
        {links.map((link, index) => <Link key={index} {...link}/>)}
      </Grid>
    </Grid>
  );
};

export default DesktopHeader;