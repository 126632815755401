import React from "react";
import Typography from "@mui/material/Typography";
import ShopBreadcrumbs from "../../components/Shop/ShopBreadcrumbs/ShopBreadcrumbs";
import ContactUs from "../../components/ContactUs/ContactUs";
import MainFooter from "../../components/MainFooter/MainFooter";
import OfferTabsContainer from "../../components/Shop/Offer/OfferTabsContainer";
import HeaderContainer from "../../components/Header/Header/HeaderContainer";
import ShopCartHeaderContainer from "../../components/Shop/ShopCartHeader/ShopCartHeaderContainer";
import s from "./ConfirmPurchase.module.css"


function ConfirmPurchase(props) {
  document.title = "БПК | Заказ";

  const breadcrumbs = [
      { name: "Магазин", to: "/shop" },
      { name: "Корзина", to: "/shop/cart" },
      { name: "Оформление заказа", to: "/shop/cart" },
    ];

  return (
    <div>
      <div>
        <HeaderContainer />
      </div>

      <div style={{ marginTop: "30px", marginBottom: "30px" }}>
        <ShopCartHeaderContainer />
        <Typography className={s.pageTitle}>
          Оформление заказа
        </Typography>
        <ShopBreadcrumbs breadcrumbs={breadcrumbs} marginLeft={"10%"}/>

        <div style={{ width: "80%", margin: "auto", paddingBottom: "50px", paddingTop: "40px" }}>
          <OfferTabsContainer />
        </div>
      </div>

      <div className={"white"}>
        <ContactUs />
      </div>
      <div>
        <MainFooter />
      </div>
    </div>
  );
}

export default ConfirmPurchase;
