import React from "react";
import Typography from "@mui/material/Typography";
import MKButton from "../../../../../components/MKButton";
import { NavLink } from "react-router-dom";
import Grid from "@mui/material/Grid";
import s from "./Cart.module.css";


function MobileCartSum(props) {
  const { productsInfo, productsCart } = props;
  let cartSum = 0;

  for (let productId in productsCart) {
    if (productId in productsInfo) {
      cartSum += productsCart[productId] * (productsInfo[productId]["price"] - productsInfo[productId]["discount"]);
    }
  }

  return (
    <Grid width={"80%"} margin={"auto"} border={1} minHeight={"100px"}
          marginTop={5} borderColor={"#aaaaaa"} borderRadius={2}
          container alignItems={"center"} justifyContent={"center"}
          paddingY={1}
          sx={{ backgroundColor: "#ffffff" }}
    >
      <Grid item>
        <Typography className={s.sumText}>
          Итого: {cartSum} руб.
        </Typography>
      </Grid>

      <Grid item>
        <NavLink to={"/shop/offer"}>
          <MKButton color={"primary"} className={s.submitButton}>
            Оформить заказ
          </MKButton>
        </NavLink>
      </Grid>
    </Grid>
  );
}

export default MobileCartSum;
