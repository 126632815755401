import React from "react";
import ContactUs from "../../components/ContactUs/ContactUs";
import MainFooter from "../../components/MainFooter/MainFooter";
import Typography from "@mui/material/Typography";
import ListProducts from "../../components/Shop/Product/ListProducts";
import ShopBreadcrumbs from "../../components/Shop/ShopBreadcrumbs/ShopBreadcrumbs";
import HeaderContainer from "../../components/Header/Header/HeaderContainer";
import ShopCartHeaderContainer from "../../components/Shop/ShopCartHeader/ShopCartHeaderContainer";
import Grid from "@mui/material/Grid";
import { BeatLoader } from "react-spinners";
import s from "./ShopCategory.module.css";

function ShopCategory(props) {
  const { products, category } = props;


  if (!products || !category) {
    return (
      <>
        <HeaderContainer />
        <div style={{ marginTop: "30px", marginBottom: "30px" }}>
          <ShopCartHeaderContainer />
          <Typography display={"flex"} className={s.pageTitle}>
            Магазин
          </Typography>
          <Grid container width={"70%"} margin={"auto"} justifyContent={"center"} alignItems={"center"}
                minHeight={280}>
            <BeatLoader size={13} color={"#123abc"} />
          </Grid>
        </div>
        <div className="white">
          <ContactUs />
        </div>
        <div>
          <MainFooter />
        </div>
      </>
    );
  }

  let breadcrumbs = [
    { name: "Категории", to: "/shop" },
    { name: category["title"], to: `/shop/${category["id"]}` },
  ];

  document.title = `БПК | ${category["title"]}`;


  return (
    <>
      <HeaderContainer />
      <div style={{ marginTop: "30px", marginBottom: "30px" }}>
        <ShopCartHeaderContainer />
        <Typography display={"flex"} className={s.pageTitle}>
          Магазин
        </Typography>
        <ShopBreadcrumbs breadcrumbs={breadcrumbs} />
        <ListProducts products={products} />
      </div>
      <div className="white">
        <ContactUs />
      </div>
      <div>
        <MainFooter />
      </div>
    </>
  );
}

export default ShopCategory;
