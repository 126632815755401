import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import styles from "./Link.module.css";
import "./ActiveLink.css"


function Link(props) {
  const { name, component, ...rest } = props;

  return (
    <Box className={styles.link_box}
         component={component}
         {...rest}
    >
      <Typography className={styles.link_text}>
        {name}
      </Typography>
    </Box>
  );
}

export default Link;
