import React from "react";
import Grid from "@mui/material/Grid";
import CategoryCard from "./CategoryCard";
import { BeatLoader } from "react-spinners";

function ListCategories(props) {
  const {categories} = props;


  if (categories === undefined) {
    return (
      <Grid container width={"70%"} margin={"auto"} justifyContent={"center"} alignItems={"center"}
            minHeight={280}>
        <BeatLoader size={13} color={"#123abc"} />
      </Grid>
    );
  }

  const categoryCards = categories.map(category => {
    return (
      <Grid key={category.id} item>
        <CategoryCard
          title={category.title}
          image={category.image}
          id={category.id}
        />
      </Grid>
    );
  });



  return (
    <Grid container width={"70%"} margin={"auto"} justifyContent={"center"} marginTop={5} rowSpacing={3}
          columnSpacing={4}
          minHeight={280}>
      {categoryCards}
    </Grid>
  );
}

export default ListCategories;
