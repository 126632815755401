import React from "react";
import Grid from "@mui/material/Grid";
import MKButton from "../../../../components/MKButton";

function ButtonAmount(props) {
  const { productId, minValue, unit, addProduct, removeProduct, productsCart } = props;

  let productCount = productsCart[productId] || 0;

  if (productCount > 0) {
    return (
      <Grid item alignItems={"center"}>
        <Grid container columnSpacing={1} justifyContent={"center"}>
          <Grid item>
            <MKButton color={"primary"} variant={"gradient"} size={"small"}
                      onClick={() => removeProduct(productId, minValue)}>
              -
            </MKButton>
          </Grid>

          <Grid item>
            <MKButton color={"dark"} variant={"outlined"} size={"small"}> {productCount} {unit} </MKButton>
          </Grid>

          <Grid item>
            <MKButton color={"primary"} variant={"gradient"} onClick={() => addProduct(productId)}
                      size={"small"}> + </MKButton>
          </Grid>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container justifyContent={"center"} alignItems={"center"}>
        <Grid item onClick={() => addProduct(productId)}>
          <MKButton color={"info"} variant={"gradient"}> Добавить в корзину </MKButton>
        </Grid>
      </Grid>
    );
  }
}

export default ButtonAmount;
