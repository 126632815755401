import React from "react";
import Grid from "@mui/material/Grid";
import MenuIcon from "@mui/icons-material/Menu";
import { Drawer, List } from "@mui/material";
import Box from "@mui/material/Box";
import MobileLink from "./MobileLink/MobileLink";
import styles from "./MobileHeader.module.css";

const MobileHeader = ({ showMobileHeader, toggleMobileHeader, links }) => {

  return (
    <Grid container justifyContent={"flex-end"} alignItems={"center"} className={styles.mobile_header}>
      <Grid className={styles.menu_button} item onClick={() => toggleMobileHeader(true)}>
        <MenuIcon />
      </Grid>

      <Drawer open={showMobileHeader} anchor={"right"} onClose={() => toggleMobileHeader(false)}>
        <Box className={styles.mobile_menu} role="presentation"
             onClick={() => toggleMobileHeader(false)}
             onKeyDown={() => toggleMobileHeader(false)}>
          <List>{links.map((page, index) => <MobileLink key={index} {...page} />)}</List>
        </Box>
      </Drawer>
    </Grid>
  );
};

export default MobileHeader;