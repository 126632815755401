import React from "react";
import { Breadcrumbs, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import s from "./ShopBreadcrumbs.module.css";

function ShopBreadcrumbs({ breadcrumbs, marginLeft="15%" }) {
  let navLinks = [];

  const link_breadcrumbs = breadcrumbs.slice(0, -1);
  const last_breadcrumb = breadcrumbs.at(-1);

  link_breadcrumbs.forEach((link) => {
    navLinks.push(
      <NavLink className={s.link} to={link.to} key={link.to}>{link.name}</NavLink>,
    );
  });


  navLinks.push(
    <Typography className={s.lastLink} key={last_breadcrumb.to}>
      {last_breadcrumb.name}
    </Typography>,
  );

  return (
    <div className={s.breadcrumbs} style={{ marginLeft: marginLeft}}>
      <Breadcrumbs aria-label="breadcrumb">
        {navLinks}
      </Breadcrumbs>
    </div>
  );
}

export default ShopBreadcrumbs;
