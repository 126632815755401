import React from "react";
import Typography from "@mui/material/Typography";
import ShopBreadcrumbs from "../../components/Shop/ShopBreadcrumbs/ShopBreadcrumbs";
import CartSum from "../../components/Shop/Cart/Desktop/CartSum";
import CartTable from "../../components/Shop/Cart/Desktop/CatTable";
import ContactUs from "../../components/ContactUs/ContactUs";
import MainFooter from "../../components/MainFooter/MainFooter";
import HeaderContainer from "../../components/Header/Header/HeaderContainer";
import ShopCartHeaderContainer from "../../components/Shop/ShopCartHeader/ShopCartHeaderContainer";
import s from "./Cart.module.css";

function ShopCart(props) {
  document.title = "БПК | Корзина";

  const breadcrumbs = [
    { name: "Магазин", to: "/shop" },
    { name: "Корзина", to: "/cart" },
  ];

  const { productsInfo, productsCart } = { ...props };

  if (productsInfo === undefined) {
    return <></>;
  }

  return (
    <div>
      <HeaderContainer />
      <div style={{ marginTop: "30px", marginBottom: "30px"}}>
        <ShopCartHeaderContainer />
        <Typography className={s.pageTitle}>
          Корзина
        </Typography>
        <ShopBreadcrumbs breadcrumbs={breadcrumbs} />
        <CartSum productsInfo={productsInfo} productsCart={productsCart} />
        <CartTable productsInfo={productsInfo} productsCart={productsCart} />
      </div>
      <div className={"white"}>
        <ContactUs />
      </div>
      <div>
        <MainFooter />
      </div>
    </div>
  );
}

export default ShopCart;
