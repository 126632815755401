import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import { NavLink } from "react-router-dom";
import styles from "./Header.module.css";
import DesktopHeader from "../DesktopHeader/DesktopHeader";
import MobileHeader from "../MobileHeader/MobileHeader";
import PropTypes from "prop-types";
import logo from "../../../../assets/images/logo/color.png"


const Header = ({ mobileView, showMobileHeader, links, toggleMobileHeader }) => (
  <AppBar
    sx={{ boxShadow: 10 }}
    className={styles.app_bar}
  >
    <Toolbar variant="dense" className={styles.tool_bar}>
      <Grid container alignItems={"center"} justifyContent={"space-between"}>
        <Grid item ml={3} xs={2} minWidth={125} component={NavLink} to={"/"}>
          <img src={logo} className={styles.logo_image} alt={"Логотип"}/>
        </Grid>

        {!mobileView && <DesktopHeader links={links} />}

        {mobileView && <MobileHeader toggleMobileHeader={toggleMobileHeader}
                                     showMobileHeader={showMobileHeader}
                                     links={links} />}
      </Grid>
    </Toolbar>
  </AppBar>
);

Header.propTypes = {
  mobileView: PropTypes.bool.isRequired,
  showMobileHeader: PropTypes.bool.isRequired,
  links: PropTypes.array.isRequired,
  toggleMobileHeader: PropTypes.func.isRequired,
};


export default Header;
