import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "./Tabs/TabPanel";
import Box from "@mui/material/Box";
import Input from "@material-ui/core/Input";
import Grid from "@mui/material/Grid";
import LastTab from "./Tabs/LastTab";
import styles from "./OfferTabs.module.css"


export default function OfferTabs(props) {
  const {
    offerForm, cartSum, changeFormFieldByEvent, changeFormFieldByValue, submitOfferForm,
  } = props;


  return (
    <Box
      sx={{ flexGrow: 1, backgroundColor: "#ffffff", display: "flex", height: 300, borderRadius: 5 }}
    >
      <Tabs
        orientation="vertical"
        variant="standard"
        value={offerForm.currentTab}
        onChange={(event, newValue) => {
          changeFormFieldByValue("currentTab", newValue);
        }}
        sx={{ borderRight: 1, borderColor: "divider" }}
      >
        <Tab label="Адрес" className={styles.tabText}/>
        <Tab label="Информация о получателе" className={styles.tabText}/>
        <Tab label="Подтверждение заказа" className={styles.tabText}/>
      </Tabs>
      <TabPanel value={offerForm.currentTab} index={0} style={{ width: "100%"}}>
        <Grid container direction={"column"} >
          <Input
            placeholder="Адрес доставки *"
            value={offerForm.address}
            onChange={event => {
              changeFormFieldByEvent("address", event);
            }}
            required
            className={styles.input_field}
          />
          <Input
            placeholder="Индекс *"
            value={offerForm.addressIndex}
            onChange={event => {
              changeFormFieldByEvent("addressIndex", event);
            }}
            required
            className={`${styles.input_field} ${styles.mt40}`}
          />
        </Grid>
      </TabPanel>
      <TabPanel value={offerForm.currentTab} index={1} style={{ width: "100%"}}>
        <Grid container direction={"column"}>
          <Input
            placeholder="Email *"
            value={offerForm.email}
            onChange={event => {
              changeFormFieldByEvent("email", event);
            }}
            required
            className={`${styles.input_field}`}
          />
          <Input
            placeholder="Телефон *"
            value={offerForm.phone}
            onChange={event => {
              changeFormFieldByEvent("phone", event);
            }}
            required
            className={`${styles.input_field} ${styles.mt40}`}
          />
          <Input
            placeholder="ФИО получателя *"
            value={offerForm.fio}
            onChange={event => {
              changeFormFieldByEvent("fio", event);
            }}
            required
            className={`${styles.input_field} ${styles.mt40}`}
          />
          <Input
            value={offerForm.comment}
            onChange={event => {
              changeFormFieldByEvent("comment", event);
            }}
            placeholder="Комментарий к заказу"
            className={`${styles.input_field} ${styles.mt40}`}
          />
        </Grid>
      </TabPanel>
      <TabPanel value={offerForm.currentTab} index={2} style={{ width: "100%"}}>
        <LastTab offerForm={offerForm} changeFormFieldByValue={changeFormFieldByValue}
                 cartSum={cartSum} submitOfferForm={submitOfferForm} />
      </TabPanel>
    </Box>
  );
}

