import { configureStore } from "@reduxjs/toolkit";
import thunkMiddleware from "redux-thunk";
import vacancyReducer from "./reducers/vacancy-reducer";
import serviceReducer from "./reducers/service-reducer";
import headerReducer from "./reducers/header-reducer";
import shopReducer from "./reducers/shop-reducer";
import { reducer as formReducer } from 'redux-form'

const rootReducer = {
  vacancyPage: vacancyReducer,
  servicePage: serviceReducer,
  header: headerReducer,
  shop: shopReducer,
  form: formReducer,
};

let store = configureStore({
  reducer: rootReducer,
  middleware: [thunkMiddleware],
});

window.store = store;

export default store;