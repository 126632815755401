import React from "react";
import { connect } from "react-redux";
import { getServices } from "../../../../redux/reducers/service-reducer";
import ServicesList from "./ServicesList";

class ServiceListContainer extends React.Component {
  componentDidMount() {
    this.props.getServices()
  };

  render() {
    return <ServicesList {...this.props}/>;
  }
}

const mapStateToProps = (state) => ({
  services: state.servicePage.services,
});

export default connect(mapStateToProps, { getServices })(ServiceListContainer);