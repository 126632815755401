import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { BeatLoader } from "react-spinners";
import ContactPhoneBackForm from "./ContactPhoneBackForm/ContactPhoneBackForm";
import s from "./ContactPhoneBack.module.css"

const ContactPhoneBack = (props) => {
  const { status, loading, submitForm } = props;

  const onSubmit = (values) => {
    if (loading === true || status !== undefined) {
      return false;
    }
    submitForm(values.name, values.phone, values.capcha);
  };

  if (loading === true) {
    return (
      <>
        <Grid container justifyContent={"center"} alignItems={"center"} minHeight={150}>
          <BeatLoader size={20} color={"#123abc"} />
        </Grid>
      </>
    );
  } else if (status === true) {
    return (
      <>
        <Grid container direction={"column"} justifyContent={"center"} alignItems={"center"} minHeight={150}>
          <Typography className={s.title}>
            Ваша заявка отправлена!
          </Typography>
          <Typography className={s.title}>
            Ожидайте звонка!
          </Typography>
        </Grid>
      </>
    );
  } else if (status === false) {
    return (
      <>
        <Grid container direction={"column"} justifyContent={"center"} alignItems={"center"} minHeight={150}>
          <Typography className={s.title}>
            Произошла ошибка
          </Typography>
          <Typography className={s.title}>
            Пожалуйста, попробуйте позже
          </Typography>
        </Grid>
      </>
    );
  }

  return (
    <>
      <Grid
        container
        spacing={2}
        direction="column"
        alignItems={"center"}
      >
        <Grid item textAlign={"center"}>
          <Typography fontSize={"20px"} className={s.title}>
            Закажите обратный звонок
          </Typography>
        </Grid>

        <ContactPhoneBackForm onSubmit={onSubmit} />
      </Grid>
    </>
  );
};


export default ContactPhoneBack;
