import React from "react";
import { CardContent, CardMedia, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import ButtonsAmountContainer from "../buttons/ButtonsAmountContainer";
import s from "./ProductCard.module.css"

function ProductCard(props) {
  const { price, title, image, discount, productId, amount_status, unit } = props;

  let priceRow = <Box display="inline">{price} р.</Box>;
  if (!price) {
    priceRow = <Box display="inline"> По запросу </Box>;
  }
  if (discount !== 0) {
    priceRow = <>
      <Box display="inline" paddingRight={1}>{price - discount} р.</Box>
      <Box display="inline"><s>{price} р.</s></Box>
    </>;
  }
  return (
    <Card sx={{ height: 350, width: 280 }}>
      <Grid container direction={"column"} justifyContent={"center"} alignItems={"center"} height={"100%"}>
        <Grid item xs={3} paddingTop={2} sx={{maxHeight: "150px"}}>
          <CardMedia
            component="img"
            sx={{
              width: "100%", marginX: 0, marginY: "auto",
              objectFit: "cover", maxHeight: "150px", height: "auto",
            }}
            image={image}
            alt={title}
          />
        </Grid>
        <Grid item paddingTop={1} xs>
          <CardContent sx={{ height: "100%", paddingX: 2}} style={{ paddingBottom: 0}}>
            <Grid container direction={"column"} alignItems={"center"} justifyContent={"center"} height={"100%"}>
              <Grid item xs={4} sx={{ maxHeight: "33%"}}>
                <Typography className={s.cardTitle} component="div">
                  {title}
                </Typography>
              </Grid>

              <Grid item xs={2}>
                <Typography
                  fontFamily={"Roboto"}
                  fontWeight={"bold"}
                  textAlign={"center"}
                  fontSize={16}
                  display={"inline-block"}
                  component={"span"}
                >
                  Цена: {priceRow}
                </Typography>
              </Grid>
              <Grid item >
                <Typography fontSize={12} paddingBottom={2} fontFamily={"Roboto"} >
                  * {amount_status}
                </Typography>

              </Grid>

              <Grid item xs={3}>
                <div>
                  <ButtonsAmountContainer productId={productId} unit={unit} minValue={0} />
                </div>
              </Grid>
            </Grid>

          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
}

export default ProductCard;
