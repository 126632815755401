import Cookies from "universal-cookie";

const CART_COOKIE_NAME = "shopping-cart";


const setCookie = (cookies, cookieName, cookieValue) => {
  let date = new Date();
  date.setTime(date.getTime() + (360 * 24 * 60 * 60 * 1000));

  cookies.set(cookieName, cookieValue, { expires: date, sameSite: "lax", path: "/" });
};

export const productCookies = {
  addProduct: (productId) => {
    const cookies = new Cookies();
    let shoppingCart = cookies.get(CART_COOKIE_NAME);
    if (productId in shoppingCart) {
      shoppingCart[productId] += 1;
    } else {
      shoppingCart[productId] = 1;
    }

    setCookie(cookies, CART_COOKIE_NAME, shoppingCart);

    return cookies.get(CART_COOKIE_NAME);
  },

  removeProduct: (productId, minValue) => {
    const cookies = new Cookies();
    let shoppingCart = cookies.get(CART_COOKIE_NAME);

    if ((productId in shoppingCart) && (shoppingCart[productId] > minValue)) {
      shoppingCart[productId] -= 1;
    }

    if (shoppingCart[productId] <= 0) {
      delete shoppingCart[productId];
    }

    setCookie(cookies, CART_COOKIE_NAME, shoppingCart);

    return cookies.get(CART_COOKIE_NAME);
  },

  fullRemoveProduct: (productId) => {
    const cookies = new Cookies();
    let shoppingCart = cookies.get(CART_COOKIE_NAME);

    if (productId in shoppingCart) {
      delete shoppingCart[productId];
    }

    setCookie(cookies, CART_COOKIE_NAME, shoppingCart);

    return cookies.get(CART_COOKIE_NAME);
  },

  getProducts: () => {
    const cookies = new Cookies();

    if (!cookies.get(CART_COOKIE_NAME)) {
      setCookie(cookies, CART_COOKIE_NAME, {});
    }

    return cookies.get(CART_COOKIE_NAME);
  },

  clearProducts: () => {
    const cookies = new Cookies();
    setCookie(cookies, CART_COOKIE_NAME, {});
    return {}
  },
};