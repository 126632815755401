import React from "react";
import { getCategories } from "../../../../redux/reducers/shop-reducer";
import { connect } from "react-redux";
import ListCategories from "./ListCategories";


class ListCategoriesContainer extends React.Component {
  componentDidMount() {
    this.props.getCategories();
  }

  render() {
    let categories = this.props.categories;
    if (this.props.categories) {
      categories = Object.entries(this.props.categories).map(([id, category]) => category);
    }

    return <ListCategories categories={categories}/>
  }

}

const mapStateToProps = (state) => ({
  categories: state.shop.categories,
})


export default connect(mapStateToProps, { getCategories })(ListCategoriesContainer);