import React from "react";
import ContactPhoneBack from "./ContactPhoneBack";
import { contactApi } from "../../../../api/api";


class ContactPhoneBackContainer extends React.Component {
  state = {
    loading: false,
    status: undefined,
  };

  sendFeedback = async (name, phone, capcha) => {
    this.setState({
      loading: true,
    });

    try {
      const data = await contactApi.sendContact(name, phone, capcha);
      this.setState({
        loading: false,
        status: data.status === "ok",
      });
    } catch (e) {
      console.error(e.message);
      this.setState({
        loading: false,
        status: false,
      });
    }

  };

  render() {
    return <ContactPhoneBack submitForm={this.sendFeedback}
                             loading={this.state.loading}
                             status={this.state.status} />;
  }
}

export default ContactPhoneBackContainer;